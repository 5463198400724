.devcard-main {
  width: 350px;
  height: 400px;
  border: 1px solid #ffffff50;
  display: grid;
  grid-template-rows: 25% 50% 25%;
  grid-template-columns: 100%;
  transition: 0.5s;
  border-radius: 20px;
  background-color: #00000050;
}

.devcard-main:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px #ff69b4;
}

.devcard-main > div {
  /* border: 1px solid white; */
  overflow: hidden;
}

.devcard-head {
  border-bottom: 1px solid #ffffff50;
  overflow: hidden;
}

.devcard-head > img {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-radius: 20px;
}

.devcard-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.devcard-body > div {
  font-family: "Poppins", sans-serif;
}

.devcard-body-name {
  font-size: 25px;
  margin-bottom: 10px;
}

.devcard-body-desc {
  font-size: 12px;
}

.devcard-tail {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;
}

.devcard-tail img {
  transition: 0.5s;
  border-radius: 50%;
}

.devcard-tail img:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px hotpink;
}

@media (max-width: 500px) {
  .devcard-main {
    width: 300px;
  }
}
