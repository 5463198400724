.chains-body-image > img {
  height: clamp(30px, 10vw, 100px);
}

.chains-main {
  display: flex;
  gap: clamp(10px, 5vw, 50px);
  align-items: flex-start;
  justify-content: center;
}

.chains-body-support {
  font-family: "Jost", sans-serif;
  color: #ff69b4;
  letter-spacing: 2px;
  font-size: clamp(10px, 3vw, 25px);
}

.chains-body-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
