@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

.home-main {
  color: white;
  width: 100%;
  backdrop-filter: blur(5px);
  background-color: #111111dd;
  border: 1px solid transparent;
}
* {
  box-sizing: border-box;
}

#home {
  margin-top: 50px;
}

.home-preloader {
  height: 100vh;
  width: 100%;
  background-color: #000000;
  position: absolute;
  top: 0;
  left: 0;
  animation: fadeaway 1s linear forwards 1.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-logo {
  min-height: 85vh;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(2, 50%);
  padding: 0 25px 0 25px;
}
.logo-image > img {
  width: max(20vw, 200px);
}

.logo-image {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-logo-main {
  font-size: min(12vw, 120px);
  font-family: "Jost", sans-serif;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-row: 1 / 3;
  grid-column: 2 / 3;
}

.home-logo-main span {
  color: #ff69b4;
}

.eztools > span {
  color: #ff69b4;
  text-shadow: 0px 0px 50px #ff69b490;
}

.eztools {
  margin-top: 30px;
  text-shadow: 0px 0px 40px #ffffff7b;
}

.home-logo-desc {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 24px;
  padding: 25px;
  /* grid-row: 2 / 3; */
  /* grid-column: 1 / 2; */
  font-family: "Poppins", sans-serif;
}

.home-logo-title {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  display: flex;
  padding: 0 25px 0 25px;
  align-items: flex-end;
  font-size: min(12vw, 75px);
  font-family: "Poppins", sans-serif;
  line-height: 100%;
}

.home-logo-title span {
  text-decoration: underline;
  text-underline-offset: 10px;
  color: hotpink;
  text-shadow: 0px 0px 50px #ff69b4aa;
}

/* .home-subinfo {
  grid-row: 3 / 4;
  grid-column: 1 / 3;
  border-radius: 50px;
  box-shadow: inset 15px 15px 31px #070707, inset -15px -15px 31px #1b1b1b;
  border: 1px solid transparent;
  transition: 1s;
}

.home-subinfo:hover{
    border: 1px solid #ff69b450;
} */

.home-navbar {
  height: 100px;
  width: 100%;
  display: flex;
  column-gap: 25px;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #22222280;
  backdrop-filter: blur(20px);
  border-bottom: 1px solid #ff69b4;
  z-index: 999;
  box-shadow: 0 0 20px hotpink;
}

.home-navbar a {
  all: unset;
  color: #ccc;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  transition: 0.5s;
}

.home-navbar a:hover {
  color: #ff69b4;
  text-shadow: 0 0 20px #ff69b4;
  cursor: pointer;
}

.home-navbar img {
  height: 100px;
}

.section-divider {
  height: 10vh;
  width: 100%;
}
.home-info {
  width: 100%;
  margin-top: 20px;
}

.milestones {
  /* width: 25%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-body {
  padding: 0 20px 0 20px;
}

.projects-heading {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.projects-heading > span {
  color: hotpink;
}

.milestones-heading {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.milestones-heading > span {
  color: hotpink;
}

.developer-main {
  width: 100%;
  margin-bottom: 50px;
  height: 15vh;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.developer-main span {
  color: #ff69b4;
}

/* stats */

.our-stats {
  width: 100%;
  flex-wrap: wrap;
  border-top: 1px solid #ff69b4;
  border-bottom: 1px solid #ff69b4;
  padding: 10px;
  position: relative;
}

.our-stats-body {
  height: 100%;
  /* height: 200px; */
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}

.stats-body-inner {
  width: 400px;
  /* min-width: 400px; */
  align-items: center;
  display: flex;
}

.stats-body-number {
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #ff69b4;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.stats-body-support {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #ffffff;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
}
.our-stats::before {
  position: absolute;
  top: 10px;
  content: "";
  border-top: 1px solid #ff69b4;
  display: block;
  width: calc(100% - 20px);
}
.our-stats::after {
  position: absolute;
  content: "";
  border-top: 1px solid #ff69b4;
  display: block;
  width: calc(100% - 20px);
  bottom: 10px;
}

.wallet-connect-button {
  border: 1px solid #ff69b4;
  font-size: 10px;
  padding: 7px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.3s all;
}

.wallet-connect-button:hover {
  background-color: #ff69b420;
  border-radius: 10px;
}

.responsive-div {
  color: #fff;
  display: none;
}
@keyframes fadeaway {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@media (max-width: 900px) {
  .home-logo {
    margin: auto;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(5, auto);
    padding-top: 50px;
  }

  .home-logo-main {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }

  .home-logo-title {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    text-align: center;
    font-size: 10vw;
    padding: none;
  }

  .home-logo-desc {
    grid-row: 3 / 5;
    grid-column: 1 / 2;
    text-align: center;
    font-size: 18px;
  }
  .projects-body {
    padding: 0;
  }
}

.ezdevs {
  margin-bottom: 0px !important;
}

.ezdevs-body {
  font-size: 25px;
  font-family: "Jost", sans-serif;
  text-align: center;
  padding: 0 20px 0 20px;
  margin-bottom: 50px;
}

@media (max-width: 1300px) {
  .our-stats {
    padding-bottom: 20px;
  }
  .stats-body-inner {
    flex-direction: column;
    width: 200px;
  }

  .stats-body-number {
    height: 40%;
  }

  .stats-body-inner > div {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .stats-body-number {
    font-size: 50px;
  }
  .stats-body-inner {
    flex-direction: column;
    width: 300px;
  }
  .stats-body-support {
    font-size: 18px;
  }
  .our-stats-body {
    flex-direction: column;
    align-items: center;
  }
  .heading-responsive {
    font-size: 40px;
  }
  .sub-heading-responsive {
    font-size: 20px;
  }
  .home-navbar a {
    display: none;
  }
  .home-navbar {
    justify-content: space-between;
    padding-right: 20px;
  }

  .responsive-div {
    display: flex;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    align-items: center;
    transition: all 0.5s ease;
    cursor: pointer;
  }
}

.responsive-div:hover {
  transform: scale(1.05);
}

.img-wrap {
  width: 100px;
  cursor: pointer;
}

.img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.discord-img {
  height: 40px !important;
  width: 40px !important;
}

.nft-img {
  height: 30px !important;
  width: 30px !important;
  margin-bottom: 5px;
  margin-top: 5px;
}

@media (max-width: 400px) {
  .img-wrap {
    display: none;
  }

  .responsive-hide {
    width: 100%;
    justify-content: center;
  }
}
.pie-contain {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
