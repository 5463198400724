.pie-wrap {
  height: 300px;
  width: 300px;
  position: relative;
}

.inner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-stats {
  font-size: 40px;
  display: flex;
  gap: 5px;
  color: hotpink;
  align-items: baseline;
}

.small-cap {
  color: white;
  margin-top: -5px;
  font-size: 12px;
}

.pie-caption {
  margin-top: -5px;
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.pie-caption > span {
  color: hotpink;
}
