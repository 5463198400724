.gridwrap {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  display: grid;
  /* flex-wrap: wrap; */
  max-height: 85%;
  gap: 30px;
  padding: 20px 10px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.connect {
  border: 1px solid #ff69b4;
  border-radius: 10px;
  padding: 8px 15px;
  color: #ff69b4;
  font-size: 12px;
}

.nonft {
  width: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  color: #fff;
}

.header {
  display: flex;
  justify-content: center;

  padding: 0 10px;
  align-items: center;
}
.loading {
  height: 500px;
  display: inline-block;
  padding-top: calc(220px);
  padding-left: calc(50% - 60px);
  text-align: center;
  color: #ff69b4;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}
h1 {
  font-size: 3rem;
  font-weight: 600;
  color: #fff;
}
::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  background: #ff69b410;
}
::-webkit-scrollbar-thumb {
  background: #ff69b475;
  border-radius: 10px;
}

.main {
  width: 97vw;
  margin: auto auto;
  margin-top: 1.5vh;
  height: 80vh;
  border: solid #ff69b4;
  border-color: #ff69b4 #fca6d1 #fbd1e6 #fca6d1;
  border-width: 2px 1px 1px 1px;
  border-radius: 20px;
  z-index: 99;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #11111175;
  backdrop-filter: blur(5px);
  padding: 10px 10px;
}

.addlist_centering {
  display: flex;
  justify-content: center;
}

.addlist_item {
  border: 1px solid hotpink;
  border-radius: 20px;
  /* padding: 10px; */
  background-color: #111111aa;
  height: 250px;
  width: 225px;
}

.addrow {
  color: #fff;
  padding: 10px 20px 10px 20px;
  font-size: 12px;
}

.image_top {
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /* border-bottom: 1px dashed hotpink; */
}

.image_top:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #111111 110%
  );
  width: 100%;
  height: 50px;
}

.bpink {
  color: #ff69b4;
  font-size: 20px;
}

.image_top img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
