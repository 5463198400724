.main {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
}

.name {
  color: #fff;
  font-size: clamp(20px, 3.5vw, 35px);
}

.name > span {
  font-size: clamp(30px, 5vw, 50px);
  color: #ff69b4;
}

.attribute {
  color: #fff;
  font-size: 13px;
  border: 1px solid hotpink;
  /* height: 30px; */
  border-radius: 200px;
  padding: 5px 10px;
  background-color: #ff69b435;

  width: max-content;
}

.content {
  /* flex-grow: 1; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 15px;
  min-width: 280px;
  max-width: 350px;
}

.loading {
  height: 500px;
  display: inline-block;
  padding-top: calc(220px);
  padding-left: calc(50% - 60px);
  text-align: center;
  color: #ff69b4;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}
.attr {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media (max-width: 400px) {
  .attr {
    flex-direction: column;
  }
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  background-color: #ff69b4;
  border-radius: 0 0 0 20px;
}
.image {
  width: clamp(200px, 40vw, 500px);
  max-height: 500px;
  aspect-ratio: 1/1;
  flex-grow: 1;
  object-fit: cover;
  margin: 0 auto;
}
