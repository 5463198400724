.card {
  width: 450px;
  height: 300px;
  position: relative;
  overflow: hidden;
  transform: rotateX(51deg) rotateZ(43deg);
  transform-style: preserve-3d;
  background-color: #010101;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 32px;
  box-shadow: 1px 1px 0 1px hotpink, -1px 0 28px 0 rgba(255, 105, 180, 0.25),
    28px 28px 28px 0 rgba(255, 105, 180, 0.25);
  transition: 0.75s ease-in-out transform, 0.75s ease-in-out box-shadow;
}
.card:hover {
  transform: translate3d(0px, -16px, 0px) rotateX(51deg) rotateZ(43deg);
  box-shadow: 1px 1px 0 1px #ff69b4, -1px 0 28px 0 rgba(255, 105, 180, 0.25),
    54px 54px 28px -10px rgba(255, 105, 180, 0.25);
}

.card > img {
  /* height: 75%; */
  width: 80%;
  border-radius: 32px;
  z-index: 10;
}

.card-shine {
  position: absolute;
  z-index: 1;
  top: -50%;
  left: -70px;
  width: 40px;
  transform: rotate(-43deg);
  translate: -100px;
  height: 200%;
  border-radius: 32px;
  background-color: #ff69b4;
  opacity: 0.15;
  transition: 0.75s cubic-bezier(0.5, 0, 0.5, 1) translate;
}

.card:hover .card-shine {
  translate: 650px;
}

@media (max-width: 500px) {
  .card {
    width: 250px;
    height: 175px;
  }

  .card:hover .card-shine {
    translate: 400px;
  }
}
