.project-main {
  width: 100%;
  min-height: 300px;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.project-wrapper {
  /* width: 70%; */
  /* min-width: 600px; */
  height: 100%;
  min-height: 300px;
  /* border: 1px solid #ffffff; */
  border-radius: 20px;
  display: flex;
  transition: box-shadow 0.5s;
  align-items: center;
}

.project-image {
  /* width: 40%; */
  width: 500px;
  /* margin-top: 6%; */
  /* border-radius: inherit; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  font-family: "Jost", sans-serif;
  font-weight: 700;
  padding: 0 20px 0 20px;
}

.project-image span {
  color: hotpink;
}

.proj-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-text {
  width: 100%;
  height: 100%;
  min-height: 300px;
  border-radius: inherit;
  font-family: "Jost", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-text > div {
  align-items: flex-end;
  padding: 10px 20px 10px 20px;
}

.project-long {
  height: 60%;
}

.left .project-text > div {
  text-align: left;
}

.project-title {
  height: 40%;
  display: flex;
  font-size: 40px;
}

.project-title span {
  color: hotpink;
}

.right .project-text > div {
  text-align: right;
  justify-content: flex-end;
}

.right {
  justify-content: flex-end;
}

.right > .project-wrapper > .proj-image-wrapper {
  order: 2;
}

@media (max-width: 1000px) {
  .project-image {
    /* width: 40%; */
    width: 400px;
  }
}

@media (max-width: 750px) {
  .project-wrapper {
    flex-direction: column;
  }

  .project-text {
    min-height: unset;
    margin-top: auto;
    margin-bottom: auto;
  }

  .project-wrapper > div {
    width: 100%;
  }

  .right > .project-wrapper > .proj-image-wrapper {
    order: unset;
  }

  .right .project-image {
    border-left: none;
  }

  .left .project-image {
    border-right: none;
  }
  .project-image {
    /* width: 40%; */
    width: 350px;
  }
  .left .project-text > div {
    text-align: center;
  }

  .right .project-text > div {
    text-align: center;
    /* justify-content: flex-end; */
  }

  .project-title {
    justify-content: center !important;
  }
}
