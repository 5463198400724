@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");

.revenue-input {
  all: unset;
  border: 1px solid hotpink;
  border-radius: 10px;
  padding: 10px;
  color: white;
  font-size: 12px;
  width: 100%;
  background-color: #111111;
}

.common-combo {
  display: flex;
  width: 300px;
  justify-content: center;
  gap: 15px;
}

.nft-select-value {
  border: 1px solid hotpink;
  padding: 7px;
  height: 40px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
}

.common-nft-title {
  font-size: 20px;
  color: white;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
}
.common-body {
  width: 140px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.value-custom {
  grid-column: 1/3;
  position: relative;
  margin-bottom: 15px;
}

.value-custom::after {
  content: "Custom Input";
  position: absolute;
  bottom: -20px;
  font-size: 11px;
  color: hotpink;
}

.nft-selected {
  background-color: #ff69b450;
}

.combo-after {
  position: relative;
}

.combo-after::after {
  content: "1 set = 5 NFTs";
  position: absolute;
  bottom: -10px;
  font-weight: 400;
  font-size: 11px;
  color: hotpink;
  left: 0;
  width: 100%;
}
.control-panel {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
}
.rev-calc {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}

.rev {
  font-size: 20px;
  color: white;
  position: relative;
}

.your-rev,
.our-rev {
  /* width: 50px; */
  min-width: 85px;
  text-align: center;
}
.rev-ratio {
  font-size: 15px;
  color: hotpink;
  position: relative;
}
.your-rev::after {
  content: "holders' cut";
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: hotpink;
}

.our-rev::after {
  content: "our revenue";
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: hotpink;
}

.revenue-wrap {
  /* border: 1px solid red; */
  display: flex;
  width: 300px;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.range-wrap-custom {
  border-top: 2px solid #ff69b430;
  border-bottom: 2px solid #ff69b430;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
}

.range-wrap-track {
  height: 15px;
  position: absolute;
  background-color: hotpink;
  opacity: 0.5;
}
#revenue {
  z-index: 90;
  width: 250px;
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */
}

#revenue::-ms-track {
  width: 250px;
  cursor: pointer;
  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

#revenue::-webkit-slider-thumb {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 15px;
  height: 40px;
  border-radius: 10px;
  background: #ff69b4;
  border: 2px solid hotpink;
  cursor: pointer;
}

.pool-desc {
  text-align: center;
}

.pool-desc span {
  color: hotpink;
  font-size: 20px;
  font-weight: bold;
}

.your-sub {
  margin-top: 10px;
  font-size: 12px;
  color: hotpink;
  border-left: 1px solid hotpink;
  border-right: 1px solid hotpink;
  border-radius: 5px;
  padding: 0 5px 0 5px;
}

.you-own {
  /* border: 1px solid red; */
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}

.footnote {
  margin-top: 30px;
  font-size: 13px;
  color: hotpink;
  text-align: center;
  max-width: max(600px, 75vw);
  padding: 0 10px 0 10px;
  margin-left: auto;
  margin-right: auto;
}

.you-own span {
  color: hotpink;
  font-weight: bold;
}

.calc-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.ye {
  font-size: 20px;
}

.earn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.earnings-box {
  font-family: "Major Mono Display", monospace;
  border: 1px solid hotpink;
  position: relative;
  border-radius: 10px;
  font-size: 14px;
  padding: 5px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.eb-min::after {
  font-family: "Poppins", sans-serif;
  content: "MIN";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: hotpink;
}

.eb-max::after {
  font-family: "Poppins", sans-serif;
  content: "MAX";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: hotpink;
}

.earn-wrap > span {
  font-family: "Major Mono Display", monospace;
  font-size: 25px;
  color: hotpink;
}
