@tailwind base;
@tailwind components;
@tailwind utilities;

.home-main {
    overflow-x: hidden;
}

.input-value{
    background-color: black;
    border: 1px solid hotpink;
    padding: 7px;
    height: 40px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    align-items: center;
    outline:none;
}

.mint-img{
    max-height: 450px;
    min-height: 100px;
    margin: auto;
}
.mint-head{
    font-size: 5rem;
}