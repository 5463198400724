.the-button {
  all: unset;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  color: hotpink;
  border: 1px solid hotpink;
  padding: 10px;
  border-radius: 10px;
  transition: 0.5s;
  cursor: pointer;
}

.the-button:hover {
  transform: scale(1.05);
  background-color: #ff69b424;
}

.button-main > a {
  text-decoration: none;
}

.not-a-button {
  all: unset;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  color: hotpink;
  border-bottom: 1px solid hotpink;
  padding: 10px;
  transition: 0.5s;
}
