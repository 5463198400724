.counter-count {
  width: clamp(250px, 20vw, 300px);
  height: 40px;
  border-radius: 15px;
  border: 1px solid hotpink;
  overflow: hidden;
}

.counter-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.counter-span {
  background-color: #ff69b475;
  height: 100%;
}

.counter-stats {
  margin-top: 10px;
  font-size: clamp(15px, 2vw, 22px);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
