@tailwind base;
@tailwind components;
@tailwind utilities;
.nft-desc {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: 100px;
  font-family: "Poppins", sans-serif;
}

.home-main {
  overflow-x: hidden;
}

.wl-logo-main {
  height: 200%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-logo.logo-wl {
  min-height: unset;
}
.wl-landing .home-logo-title {
  flex-direction: column;
  grid-row: 1 / 3;
  grid-column: 1 / 2;
  display: flex;
  padding: 0 25px 0 25px;
  line-height: normal;
  align-items: flex-start;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}
.whitelist-img > img {
  width: 50vw;
  max-width: 500px;
}

.whitelist-content {
  display: flex;
  padding: 60px;
  gap: 100px;
}
#no-ml {
  margin-left: 0px !important;
  padding-left: 0px;
}
.whitelist-desc {
  font-size: 20px;
  padding: 10px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wl-onecol {
  border: 1px solid #ff69b490;
  border-radius: 10px;
  width: calc(50% - 5px);
  flex-grow: 1;
  min-width: 300px;
  max-width: 600px;
  text-align: center;
  padding: 20px 10px 20px 10px;
  font-size: 15px;
}

.onecol-head {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ff69b4;
  padding-bottom: 25px;
  border-bottom: 1px solid hotpink;
}

.onecol-head::after {
  content: attr(custattr);
  font-size: 12px;
  color: white;
  display: block;
  width: 100%;
  height: 1px;
}
.sub-heading {
  font-size: min(8vw, 30px);
  padding: 20px 0 10px 0;
  width: 100%;
  /* display: flex; */
  align-items: center;
  /* text-align: start; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.sub-heading > span {
  color: #ff69b4;
}
.presale-heading {
  margin-top: 16px;
  font-size: min(3vw, 24px);
  color: hotpink;
}

.expandable-div {
  margin-top: 25px;
  max-height: 100px;
  overflow: hidden;
  position: relative;
  transition: 0.5s all;
  padding: 0 20px 0 20px;
}

.collapse-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #ff69b4;
  font-size: 15px;
}

.arrow-img {
  height: 30px;
  filter: brightness(1);
  transform: rotate(270deg);
  transition: 0.5s all;
}

.btn-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.expandable-div:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #111111 90%
  );
  width: 100%;
  height: 50px;
  transition: 0.5s all;
}
.illustration {
  display: flex;
  margin-top: 20px;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}
.ill-total {
  padding: 10px;
  border: 1px dashed #ff69b4;
  display: grid;
  gap: 7px;
  width: max-content;
  grid-template-columns: repeat(10, 1fr);
}
.ill-breakdown {
  display: flex;
  gap: 10px;
}

.ill-breakdown-item {
  padding: 10px;
  flex-wrap: wrap;
  position: relative;
  border: 1px dashed #ff69b4;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  width: 100px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.ill-breakdown-item::after {
  content: attr(custattr);
  position: absolute;
  width: 100%;
  bottom: -18px;
  font-size: 11px;
  text-align: center;
}

.ill-breakdown-item::before {
  content: attr(person);
  position: absolute;
  width: 100%;
  top: -18px;
  font-size: 11px;
  text-align: center;
}
.ill-single {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.expandable-div.expanded {
  max-height: 2000px;
}

.arrow-img-expanded {
  transform: rotate(90deg);
}

.expandable-div.expanded:after {
  height: 0;
}
.section-heading {
  font-size: min(9vw, 55px);
  height: 15vh;
  width: 100%;
  text-align: center;
  padding: 60px;
  margin-bottom: 2rem;

  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.col-heading {
  font-size: min(8vw, 30px);
  text-align: center;
  margin-bottom: 10px;
}

.section-heading > span {
  color: hotpink;
}

li {
  margin: 10px;
}
.hero-desc {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-size: 17px;
  padding: 25px;
  margin-left: 20px;
  height: max-content;
  gap: 15px;
  font-family: "Poppins", sans-serif;
}

.tac {
  text-align: center;
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.com-mul {
  border-bottom: 1px solid hotpink;
  color: #ff69b4;
  padding: 2px;
  position: relative;
}
.com-mul::after {
  content: attr(custattr);
  position: absolute;
  bottom: 0px;
  left: 0px;
  font-size: 11px;
  display: block;
  width: 100%;
  height: 1px;
}

#no-ml {
  margin-left: 0;
}

#hero-title {
  font-size: min(12vw, 75px);
}
@media (max-width: 500px) {
  .card {
    width: 250px;
    height: 175px;
  }
}
.grid-reverse {
  order: -1;
}

@media (max-width: 900px) {
  #hero-title {
    margin: auto;
    margin-top: 100px;
  }
  .wl-logo-main {
    height: 100%;
  }
  .hero-desc {
    margin-left: 0px;
    padding: 0px;
  }
  .whitelist-img > img {
    width: 75vw;
  }
  .section-heading {
    font-size: min(8.5vw, 45px);
    height: 5vh;
    width: 100%;
    padding: 60px;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  .grid-reverse {
    order: 3;
  }
  .wl-landing .home-logo-title {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    text-align: center;
    font-size: 10vw;
    padding: none;
  }
  #no-ml {
    margin-left: 0px !important;
    /* padding-left: 25px; */
  }
  .presale-heading {
    text-align: center;
    width: 100%;
  }
  #rem-padding {
    padding: 0px !important;
  }
}

.pink {
  color: hotpink;
}

#big-text {
  display: inline-block;
  font-size: 35px;
  transform: translateY(10%);
}

.mb5 {
  padding-bottom: 10px;
}

.tiles {
  padding: 20px;
  position: relative;
}

.overlay-img {
  position: absolute;
  top: 10px;
  z-index: 1;
}
* {
  --animation-time: 15s;
}
.ghost-1 {
  animation: ghost-1 var(--animation-time) linear infinite;
}
.ghost-2 {
  animation: ghost-2 var(--animation-time) linear infinite;
}
.ghost-3 {
  animation: ghost-3 var(--animation-time) linear infinite;
}
.ghost-4 {
  animation: ghost-4 var(--animation-time) linear infinite;
}
.ghost-5 {
  animation: ghost-5 var(--animation-time) linear infinite;
}

@keyframes ghost-1 {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  18% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ghost-2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  38% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ghost-3 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  42% {
    opacity: 1;
  }
  58% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ghost-4 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  62% {
    opacity: 1;
  }
  78% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ghost-5 {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  82% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
